import React from "react";
import { GatsbyImage, getSrc, getImage } from "gatsby-plugin-image";
import { useMarkdownImage } from "../markdown-content";
import "./index.scss";

export default function Image({ name }) {
  const { title, file } = useMarkdownImage(name);
  return (
    <div className="MarkdownImage">
      <a className="MarkdownImage__wrapper" data-fslightbox data-type="image" href={getSrc(file)}>
        <GatsbyImage className="MarkdownImage__image" image={getImage(file)} alt={title} />
      </a>
    </div >
  );
};