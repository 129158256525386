import React from "react";
import "./index.scss";

export default function Embed({ title, url, aspectRatio = "16:9", loadingPlaceholder="Loading...", ...props }) {
    const match = aspectRatio.match(/^\d+:\d+$/);
    if (match === null) console.warn(`The aspect ratio '${aspectRatio}' is not a valid aspect ratio. Use an aspect ratio like '16:9'.`);
    const [width, height] = [match[1], match[2]];
    const ratioPercent = 100 * (height / width);

    return (
        <div className="Embed">
            <div className="Embed__wrapper" style={{ paddingBottom: `${ratioPercent}%` }}>
                <iframe
                    className="Embed__iframe"
                    title={title}
                    src={url}
                    frameBorder="0"
                    allow="picture-in-picture"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    {...props}
                    >
                </iframe>
                <div className="Embed__loadingPlaceholder">{loadingPlaceholder}</div>
            </div>
        </div>
    );
}