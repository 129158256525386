import React from "react";
import Embed from "./index";
import youtubeIcon from "@assets/feathericon/youtube.svg";

export default function Youtube({ title, videoId, start = 0, end = 0, ...props }) {
    return (
        <Embed
            title={title}
            url={`https://www.youtube-nocookie.com/embed/${videoId}?start=${start}&end=${end}&version=3&color=white&controls=0`}
            loadingPlaceholder={<img className="Youtube__placeholderIcon" src={youtubeIcon} alt="" aria-hidden />}
            {...props}
        />
    );
}