import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/_layout";
import MarkdownContent from "@components/markdown/markdown-content";
import StatusIcon from "@components/status-icon";
import checkIcon from "@assets/feathericon/check-circle-o-green.svg";
import "./success.scss";

export default function SuccessLayout({
  data: { mdx: { frontmatter: { title }, body: content } },
  pageContext
}) {
  return (
    <Layout title={title} {...pageContext}>
      <main id="main" className="SuccessPage">
        <div className="SuccessPage__text">
          <StatusIcon type="success" iconSrc={checkIcon} />
          
          <MarkdownContent>
            {content}
          </MarkdownContent>
        </div>

      </main>
    </Layout>
  )
};

export const pageQuery = graphql`
query Success($id: String!) {
  mdx(id: { eq: $id }) {
      frontmatter {
        title,
      }
      body
    },
  }
`;