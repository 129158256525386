import React from "react";
import Embed from "./index";
import mapIcon from "@assets/icons/location-icon.svg";

export default function GoogleMaps({ title, url, ...props }) {
    return (
        <Embed
            title={title}
            url={url}
            loadingPlaceholder={<img className="GoogleMaps__placeholderIcon" src={mapIcon} alt="" aria-hidden />}
            {...props}
        />
    );
}