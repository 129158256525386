import React from "react";
import { GatsbyImage, getSrc, getImage } from "gatsby-plugin-image";
import { useMarkdownImage } from "../markdown-content";
import "./index.scss";

export default function Gallery({ children }) {
    return (
        <section className="Gallery">
            <div className="Gallery__grid">
                {children}
            </div>
        </section>
    );
}

export function GalleryImage({ name }) {
    const { title, file } = useMarkdownImage(name);
    return (
        <a className="GalleryImage" data-fslightbox data-type="image" href={getSrc(file)}>
            <GatsbyImage className="GalleryImage__image" image={getImage(file)} alt={title} />
        </a>
    );
};