import React, { createContext, useContext } from "react";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Embed from "./embed";
import Youtube from "./embed/youtube";
import GoogleMaps from "./embed/google-maps";
import Gallery, { GalleryImage } from "./gallery";
import Image from "./image";
import { Link } from "@lib/i18n/localized-routing";
import "./markdown-content.scss";

export const MarkdownImagesContext = createContext();

export function useMarkdownImage(name) {
  const markdownImages = useContext(MarkdownImagesContext);
  if (markdownImages === null)
    console.error(`useMarkdownImages needs to be called inside a MarkdownImageContext.`);
  const image = markdownImages[name];
  if (image === undefined)
    console.error(`Image with name '${name}' not found. Did you include it in the frontmatter and does the file exist?`);
  return image;
}

const availableHeadings = [
  ({ children }) => <h1>{children}</h1>,
  ({ children }) => <h2>{children}</h2>,
  ({ children }) => <h3>{children}</h3>,
  ({ children }) => <h4>{children}</h4>,
  ({ children }) => <h5>{children}</h5>,
  ({ children }) => <h6>{children}</h6>,
  ({ children }) => <div className="H6">{children}</div>,
];

function offsetHeadings(offset = 0) {
  return Object.fromEntries(availableHeadings.slice(offset).map((heading, i) =>
    [`h${i + 1}`, heading]
  ))
}

const mdxComponents = {
  table: ({ children }) => (
    <div className="InlineTable">
      <div className="InlineTable__scrollWrapper">
        <table>{children}</table>
      </div>
    </div>
  ),
  a: ({ href, children }) => href.match(/^https?:\/\//)
    ? <a href={href}>{children}</a>
    : <Link to={href}>{children}</Link>,
  ul: ({ children, ...props }) => <div className="SectionWrapper"><ul {...props}>{children}</ul></div>,
  ol: ({ children, ...props }) => <div className="SectionWrapper"><ol {...props}>{children}</ol></div>,
  // pre: ({children}) => <pre>{children}</pre>,
  Youtube,
  GoogleMaps,
  Embed,
  Gallery,
  GalleryImage,
  Image,
}

export default function MarkdownContent({ images, children, headingOffset = 0, ...props }) {
  const imageMap = (images === null || images === undefined) ? images : propertyToKey(images, "name");
  const components = { ...offsetHeadings(headingOffset), ...mdxComponents, }
  return (
    <MarkdownImagesContext.Provider value={imageMap}>
      <MDXProvider components={components} {...props}>
        <div className="MarkdownContent">
          <MDXRenderer>
            {children}
          </MDXRenderer>
        </div>
      </MDXProvider>
    </MarkdownImagesContext.Provider>
  );
}

function propertyToKey(items, propertyName) {
  const result = {};
  for (let item of items)
    result[item[propertyName]] = item;
  return result;
}